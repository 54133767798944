import utils from "../../utilities";
import Notice from "./notice";
import { useFetchUsersBasic } from "../hooks/fetch/useFetchUsers";
import useStore from "../hooks/useStore";

const RevisionHistory = ({ data }) => {
  const clientRef = useStore((state) => state.clientRef);
  const { data: users } = useFetchUsersBasic({
    account: clientRef,
  });

  if (!data || data?.length === 0)
    return (
      <>
        <Notice message="No changes have been recorded for this section" />
      </>
    );

  return (
    <>
      <div className="notice notice__invert flex flex__mb">
        Key:
        <span className="diff--added">Added</span>
        <span className="diff--removed">Removed</span>
      </div>
      <div className="revisions-history">
        {data?.map((row, i) => {
          const user = row.userId
            ? " - " + (users?.find((u) => u.id === row.userId)?.name || "Admin")
            : "";
          return (
            <div key={row.id}>
              <h2>
                {utils.getFormattedDateTime(row.dateTime)}
                {user}
              </h2>
              <div
                className="data-table--value"
                dangerouslySetInnerHTML={{
                  __html: row.changes,
                }}
              ></div>
              {i !== data.length - 1 && <hr className="data-table--divide" />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RevisionHistory;
