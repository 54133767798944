import {
  useQuery,
  useQueryClient,
  useMutation,
  useIsMutating,
} from "react-query";
import { request } from "../../../utilities/fetch.js";

export const useFetchFileLibraries = ({ account = null, callback }) => {
  const isMutating = useIsMutating({ mutationKey: "userFileLibraries" });
  return useQuery(
    ["userFileLibraries", account],
    () =>
      request({
        url: "/file-libraries",
        params: { account: account },
      }),
    {
      refetchInterval: false,
      staleTime: 300000,
      select: (data) => data?.data?.items,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useFetchFileLibrary = ({
  account = null,
  userId,
  libraryId,
  callback,
}) => {
  const isMutating = useIsMutating({ mutationKey: "userFileLibraries" });
  return useQuery(
    ["userFileLibrary", account, userId, libraryId],
    () => {
      if (!userId || !libraryId) return Promise.resolve({});
      return request({
        url: `/file-libraries/${userId}/${libraryId}`,
        params: { account: account },
      });
    },
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 300000,
      select: (data) => data?.data,
      onSuccess: callback,
      onError: (err) => {
        console.log(err);
      },
      enabled: isMutating === 0,
    }
  );
};

export const useAddUserFiles = ({
  account = null,
  callback,
  userId = null,
  libraryId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      for (const pair of data.entries()) {
        const [key, value] = pair;
      }
      if (!userId || !libraryId) return Promise.resolve({});
      return request({
        url: `/file-libraries/${userId}/${libraryId}`,
        method: "post",
        params: { account },
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      mutationKey: "userFileLibraries",
      onSuccess: async (data) => {
        await queryClient.cancelQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        if (callback && typeof callback != "undefined") callback(data);
      },
      onSettled: async () => {
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
      },
    }
  );
};

export const useDeleteFile = ({
  account = null,
  callback,
  userId = null,
  libraryId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (fileId) => {
      if (!fileId) return Promise.resolve({});
      return request({
        url: `/file-libraries/${fileId}`,
        method: "delete",
        params: { account: account },
      });
    },
    {
      mutationKey: "userFileLibraries",
      onMutate: async (fileId) => {
        await queryClient.cancelQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        const currentLibrary = queryClient.getQueryData([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ])?.data;
        // optimistic update data
        if (currentLibrary) {
          const files = currentLibrary?.files;
          const deleteIndex = files?.findIndex((file) => file?.id === fileId);
          files.splice(deleteIndex, 1);
          await queryClient.setQueryData(
            ["userFileLibrary", account, userId, libraryId],
            {
              data: { ...currentLibrary, files: [...files] },
            }
          );
          queryClient.invalidateQueries([
            "userFileLibrary",
            account,
            userId,
            libraryId,
          ]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useUpdateFile = ({
  account = null,
  callback,
  userId = null,
  libraryId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      const fileId = data.id;
      if (!fileId) return Promise.resolve({});
      delete data.id;
      return request({
        url: `/file-libraries/${fileId}`,
        method: "patch",
        data,
        params: { account: account },
      });
    },
    {
      mutationKey: "userFileLibraries",
      onMutate: async (data) => {
        const fileId = data.id;
        await queryClient.cancelQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        const currentLibrary = queryClient.getQueryData([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ])?.data;
        // optimistic update data
        if (currentLibrary) {
          const files = currentLibrary?.files && [...currentLibrary?.files];
          const fileIndex = files?.findIndex((file) => file?.id === fileId);
          files[fileIndex] = { ...files[fileIndex], ...data };
          await queryClient.setQueryData(
            ["userFileLibrary", account, userId, libraryId],
            {
              data: { ...currentLibrary, files: [...files] },
            }
          );
          queryClient.invalidateQueries([
            "userFileLibrary",
            account,
            userId,
            libraryId,
          ]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useRenameFolder = ({
  account = null,
  callback,
  userId = null,
  libraryId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data.folder || !data.newFolder || !userId || !libraryId)
        return Promise.resolve({});
      return request({
        url: `/file-libraries/${userId}/${libraryId}`,
        method: "patch",
        data,
        params: { account: account },
      });
    },
    {
      mutationKey: "userFileLibraries",
      onMutate: async (data) => {
        await queryClient.cancelQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        const currentLibrary = queryClient.getQueryData([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ])?.data;
        // optimistic update data
        if (currentLibrary) {
          let files = currentLibrary?.files && [...currentLibrary?.files];
          files = files?.map((file) => {
            if (file?.path === data.folder) {
              file.path = data.newFolder;
            }
            return file;
          });
          await queryClient.setQueryData(
            ["userFileLibrary", account, userId, libraryId],
            {
              data: { ...currentLibrary, files },
            }
          );
          queryClient.invalidateQueries([
            "userFileLibrary",
            account,
            userId,
            libraryId,
          ]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};

export const useDeleteFolder = ({
  account = null,
  callback,
  userId = null,
  libraryId = null,
}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      if (!data.folder || !userId || !libraryId) return Promise.resolve({});
      return request({
        url: `/file-libraries/${userId}/${libraryId}`,
        method: "delete",
        data,
        params: { account: account },
      });
    },
    {
      mutationKey: "userFileLibraries",
      onMutate: async (data) => {
        await queryClient.cancelQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
        const currentLibrary = queryClient.getQueryData([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ])?.data;
        // optimistic update data
        if (currentLibrary) {
          let files = currentLibrary?.files && [...currentLibrary?.files];
          files = files?.filter((file) => {
            return file?.path !== data.folder;
          });
          await queryClient.setQueryData(
            ["userFileLibrary", account, userId, libraryId],
            {
              data: { ...currentLibrary, files },
            }
          );
          queryClient.invalidateQueries([
            "userFileLibrary",
            account,
            userId,
            libraryId,
          ]);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          "userFileLibrary",
          account,
          userId,
          libraryId,
        ]);
      },
      onSuccess: (data) => {
        if (callback && typeof callback != "undefined") callback(data);
      },
    }
  );
};
